<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    text: string
    tagline?: string
    type?: 'title' | 'subtitle'
    icon?: string
    noCaps?: boolean
    noWrap?: boolean
  }>(),
  {
    type: 'title',
  }
)

const dinamicClasses = {
  'text-2xl md:text-3xl lg:text-4xl': props.type === 'title',
  'text-lg md:text-xl lg:text-2xl': props.type === 'subtitle',
  'flex items-center gap-2': !!props.icon,
  uppercase: !props.noCaps,
}
</script>

<template>
  <div class="py-2 font-bold" :class="dinamicClasses">
    <span v-if="icon" :class="icon" />
    <span class="leading-normal" :class="noWrap ? 'text-nowrap' : ''">{{ text }}</span>
    <span
      v-if="tagline"
      class="ml-3 leading-normal text-surface-400"
      :class="noWrap ? 'text-nowrap' : ''"
    >
      {{ tagline }}
    </span>
  </div>
</template>
